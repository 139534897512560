import React from "react";
import "../../CSS/Homepage/Animation2.css";
export default function Animation() {
  return (
    <div className="animationMainDiv">
      {" "}
      <h1>
        Your Gateway to
        {/* <!-- Scroller Start --> */}
        <div className="scroller">
          <span>
            Search.
            <br />
            See.
            <br />
            Love.
            <br />
            Your Home.
          </span>
        </div>
        {/* Your Home. */}
        {/* <!-- Scroller End --> */}
      </h1>
    </div>
  );
}
